//
// Prompt
// A Non-blocking popup!
// @author Andrew Dodson
// @since Jan 2012
//
;(function ($){
  let ignorelist = [];
  try { ignorelist = JSON.parse(localStorage.getItem('prompt.bugme')) || []; } catch (e){}
  if (!ignorelist instanceof Array){
    ignorelist = [];
  }

  $.fn.popup = function (messageTitle, callback, bugme, deleteText, cancelText, message){
    // cancel if open already, return an empty jQuery object
    if ($('.jquery_prompt').length){ return $(); }

    // define callback
    callback = callback || function (p){ return !!p; };

    // in the ignore list?
    const hash = $(message).text();

    // add `ESC` + `enter` listener
    const bind = function (e){
      if (e.which === 27){
        $popup.find('form').trigger('reset');
      } else if (e.which === 13){
        $popup.find('form').trigger('submit');
      }
    };

    $(document).bind('keydown', bind);

    if (typeof deleteText === 'undefined'){
      deleteText = 'Delete';
    }
    if (typeof cancelText === 'undefined'){
      cancelText = 'Cancel';
    }
    if (typeof message === 'undefined'){
      message = '';
    }
    // build popup
    var $popup = $('<iframe class="jquery_prompt" allowtransparency=true frameborder="0" scrolling="auto" marginheight="0" marginwidth="0"></iframe>' +
							'<div class="jquery_prompt plugin">' +
								'<form>' +
									'<div class="v2 modal modal--confirm">' +
									'<button class="modal__icon modal__icon--confirm">' +
									'<svg class="icon icon-svg--question-circle"><use xlink:href="#svg--question-circle"></use></svg></button>' +
									'<div class="modal__heading">' +
									messageTitle +
									'</div><div class="modal__content modal__content--confirm">' +
									message +
									'</div><div class="modal__actions modal__actions--confirm">' +
			'<div class="btn-group btn-group--rtl">' +
			'<button type="reset" class="v2 btn btn--outline btn--md margin-right">' +
			cancelText +
			'</button>' +
			'<button type="submit" name="submit" value=1 class="v2 btn btn--primary btn--md">' +
			deleteText +
			'</button></div></div></div>' +
								'</form>' +
							'</div>'
    )
      .prependTo('body')
      .find('form')
      .prepend(message)
      .submit(function (e){
        // trigger callback
        e.response = $('button[name=submit]', this).val() == 1 ? $('input[name=text]:visible', this).val() || true : false;

        try {
          callback.call(this, e);
        } catch (e){
          e.preventDefault();
          throw e;
        }

        if (!e.isDefaultPrevented()){
          // remove event listeners
          $(document).unbind('keydown', bind);

          // dont submit the form.
          e.preventDefault();
          // prevent the system from popping these messages again
          if (!$('input[name=bugme]', this).is(':checked')){
            ignorelist.push(hash);
            try { localStorage.setItem('prompt.bugme', JSON.stringify(ignorelist)); } catch (e){}
          }
          // kill this popup
          $(this).parent().add($(this).parent().siblings('.jquery_prompt')).remove();
        } else {
          // reinstate the submit button if it was reset
          $('button[name=submit]', this).val('1');
        }
      })
      .bind('reset', function (){
        $('button[name=submit]', this).val(0);
        $(this).submit();
      })
      .find('button[type=submit]')
      .trigger('focus')
      .end()
      .end();

    // Let the user cancel these messages
    if (bugme){
      $popup.find('input[name=bugme], input[name=bugme] + label').show();
    }

    return $popup;
  };

  $.fn.prompt = function (message, callback, bugme){
    return $(this).popup(message, callback, bugme).find('input[name=text], button').show().end();
  };

  $.fn.alert = function (message, callback, bugme){
    return $(this).popup(message, callback, bugme);
  };

  $.fn.confirm = function (messageTitle, callback, bugme, deleteText, cancelText, message){
    return $(this).popup(messageTitle, callback, bugme, deleteText, cancelText, message).find('button').show().end();
  };
})(jQuery);
